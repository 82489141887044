<template>
  <div class="bt-anggota pa-5">
    <Header title="Kelompok Anggota" titleIcon="mdi-wallet" />
    <Kelompok
      class="mt-5"
      target="transaksi/dashboard"
      :list="list"
      @refreshKelompok="getKelompokAngsuran"
      :total="total"
      :is_show_total_penarikan="true"
      :is_show_total_setoran="true"
      :is_show_btn_total_setoran="false"
    />
    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>
  </div>
</template>
<script>
import helper from "@/utils/helper";
import Toast from "@/components/Toast";
import Header from "@/components/Header";
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
import Kelompok from "@/components/Kelompok.vue";
export default {
  name: "TabunganKelompok",
  components: {
    Kelompok,
    Toast,
    Header,
  },
  data() {
    return {
      list: {
        loading: false,
        kelompok: [],
        cm_code: null,
        branch_id: null,
        transaction_date: null,
        fa_code: null,
        account_cash_code: null,
      },
      alert: {
        show: false,
        msg: "",
      },
      total: 0,
      total_penarikan: 0,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...helper,
    async getKelompokAngsuran(cm_code, branch_id, transaction_date) {
      cm_code = cm_code || this.$route.params.cm_code;
      branch_id = branch_id || this.$route.params.branch_id;
      transaction_date = transaction_date || this.$route.params.tanggal;

      console.log({ cm_code, branch_id, transaction_date });

      const fa_code = this.user.fa_code || null;
      const account_cash_code = this.user.account_cash_code || null;

      let payload = new FormData();
      payload.append("cm_code", cm_code);
      payload.append("branch_id", branch_id);
      payload.append("transaction_date", transaction_date);
      payload.append("fa_code", fa_code);
      payload.append("account_cash_code", account_cash_code);

      try {
        this.list.loading = true;
        const response = await services.infoGroupSaving(
          payload,
          this.user.token
        );
        if (response.status === 200) {
          const {
            kelompok = [],
            total_setoran = 0,
            total_penarikan = 0,
          } = response.data.data;

          this.list.kelompok = kelompok;
          this.total = total_setoran;
          this.total_penarikan = total_penarikan;
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        console.log({ error });
        this.alert = {
          show: true,
          msg: error,
        };
      } finally {
        this.list.loading = false;
      }
    },
    getDate() {
      let today = new Date();
      let day = today.getDate();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();
      return `${year}-${month}-${day}`;
    },
  },
  mounted() {
    this.getKelompokAngsuran(false, false, false);
    this.list.cm_code = this.$route.params.cm_code;
    this.list.branch_id = this.$route.params.branch_id;
    this.list.transaction_date = this.$route.params.tanggal;
    this.list.fa_code = this.$route.params.fa_code;
  },
};
</script>
